<script setup lang="ts">
</script>
<template>
  <section class="wrapper">
    <div class="wrapper__content">
      <router-view/>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
